import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import SafetyWarningModal from "../../modals/SafetyWarningModal";
import MJPEGStream from "../stream/MJPEGStream";
import StreamPlaceholder from "../stream/StreamPlaceholder";
import Toolbar from "../toolbar/Toolbar";
import AudioStream from "../stream/AudioStream";

function RootScreen() {
    return (
        <Box
            flex="1"
            display="flex"
            flexDir="column"
            justifyContent="stretch"
            alignItems="stretch"
        >
            <SafetyWarningModal>
                <MJPEGStream />
                <AudioStream />
                <StreamPlaceholder />
                <Toolbar />
            </SafetyWarningModal>
        </Box>
    );
}

export default RootScreen;

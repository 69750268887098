import React, { useContext } from 'react';
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";

/*

    fonts: {
      heading: `''Mona Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
      body: `'Mona Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    },
*/

const customTheme = extendTheme({
    styles: {
      global: (props) => ({
        ".js-focus-visible :focus:not([data-focus-visible-added])": {
          outline: "none",
          boxShadow: "none",
        },
        "html, body": {
          color: "gray.600",
          backgroundColor: "gray.50",
        },
      }),
    },
    colors: {},
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    components: {
        Button: {
            variants: {
                toolbar: {
                    height: '100%',
                    borderRadius: '0px',
                    backgroundColor: 'black',
                    color: 'white',
                    _hover: {
                        backgroundColor: 'rgba(255,255,255,0.1)'
                    },
                    _active: {
                        backgroundColor: 'rgba(255,255,255,0.15)'
                    }
                },
                toolbarRed: {
                    height: '100%',
                    borderRadius: '0px',
                    backgroundColor: 'red.500',
                    color: 'white',
                    _hover: {
                        backgroundColor: 'red.600'
                    },
                    _active: {
                        backgroundColor: 'red.600'
                    }

                }
            }
        }
    }
  });
  
  function ChakraContextProvider({ children }) {
    return (
        <ChakraProvider theme={customTheme}>
          {children}
        </ChakraProvider>
    )
  }

  export default ChakraContextProvider;
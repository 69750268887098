import { Box, Button, useConst, Text, Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, } from "@chakra-ui/react";
import { useContext, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import AppContext from "../../context/AppContext";
import youtubeRedirectImg from '../../assets/img/yt-redirect.png';
import SettingsModal from "../modals/SettingsModal";

function Toolbar() {
    const [visible, setVisible] = useState(true);
    const {
        isFullscreen,
        audioActive,
        setAudioActive
    } = useContext(AppContext);

    const [fullscreenConfirmOpen, setFullscreenConfirmOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    if (visible) {
    return (
        <Box
            zIndex="2"
            position="absolute"
            right="15px"
            bottom="15px"
            shadow="dark-lg"
            background="black"
            color="white"
            borderRadius="10px"
            height="50px"
            display="flex"
            flexDir="row"
            justifyContent="stretch"
            alignItems="stretch"
            overflow="hidden"
        >
            <Button
                variant="toolbar"
                disabled={isFullscreen}
                onClick={() => {
                    setFullscreenConfirmOpen(true);
                }}
                leftIcon={(<Icon icon="fullscreen" />)}
            >Enter Fullscreen</Button>
            {/* <Box w="1px" borderRight="1px solid rgba(255,255,255,0.15)" h="100%"></Box>
            <Button
                variant="toolbar"
                onClick={() => {
                    setSettingsOpen(true);
                }}
                leftIcon={(<Icon icon="cog" />)}
            >Settings</Button> */}
            <Box flex="1"></Box>
            {/* <Box w="1px" borderRight="1px solid rgba(255,255,255,0.15)" h="100%"></Box>
            <Button
                variant={audioActive ? "toolbar" : "toolbarRed"}
                onClick={() => {
                    setAudioActive(!audioActive);
                }}
                leftIcon={(<Icon icon={audioActive ? 'volume-up' : 'volume-off'} />)}
            >{audioActive ? 'Audio On' : 'Audio Off'}</Button> */}
            <Box w="1px" borderRight="1px solid rgba(255,255,255,0.15)" h="100%"></Box>
            <Button
                variant="toolbar"
                leftIcon={(<Icon icon="chevron-down" />)}
                onClick={() => {
                    setVisible(false);
                }}
            >Hide Toolbar</Button>
            <Modal size="xl" isOpen={fullscreenConfirmOpen} onClose={() => setFullscreenConfirmOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Enter Fullscreen</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={5}>When you click Continue, the page will redirect to YouTube. Press 'GO TO SITE', which will turn on fullscreen and return to DashDisplay.</Text>
                    <Box shadow="base" borderRadius="8px" overflow="hidden" border="2px solid #3182CE">
                    <Image src={youtubeRedirectImg}></Image>
                    </Box>
                        
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' rightIcon={(<Icon icon="arrow-right" />)} onClick={(e) => {
                        e.preventDefault();
                        window.location.href = (
                            `https://youtube.com/redirect?q=${window.location.host}?fullscreenReferrer=true`
                        );
                    }}>Continue</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <SettingsModal 
                isOpen={settingsOpen}
                onConfirm={() => setSettingsOpen(false)}
                onCancel={() => setSettingsOpen(false)}
            />
        </Box>
    )
    } else {
        return (
            <Box
                zIndex="2"
                position="absolute"
                right="15px"
                bottom="15px"
                shadow="dark-lg"
                background="black"
                color="white"
                borderRadius="10px"
                borderTop="1px solid rgba(255,255,255,0.1)"
                height="50px"
                display="flex"
                flexDir="row"
                justifyContent="stretch"
                alignItems="stretch"
                overflow="hidden"
                opacity="0.5"
            >
                <Button 
                variant="toolbar"
                onClick={() => {
                    setVisible(true);
                }}><Icon icon="chevron-up" /></Button>
            </Box>
        )
    }
}

export default Toolbar;
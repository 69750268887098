import { Box, VStack, Text, HStack, Spinner } from "@chakra-ui/react";

function StreamPlaceholder() {
    return (
        <Box
            flex="1"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            padding="30px"
            backgroundColor="gray.50"
            zIndex="0"
        >
            <VStack spacing={6}>
                <VStack spacing={8}>
                    <Spinner
                        speed={"2s"}
                        emptyColor="gray.200"
                        thickness="3px"
                        size="xl"
                        color="gray.600"
                    />
                    <Text fontSize="32px" fontWeight="bold">
                        Searching for Stream...
                    </Text>
                </VStack>
                <Box>
                    <Text fontSize="18px">
                        Please launch the DashDisplay Mac application, and check
                        that your vehicle is connected to the shared Wifi
                        network created by your Mac.
                    </Text>
                </Box>
            </VStack>
        </Box>
    );
}

export default StreamPlaceholder;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Text, Button, Link, Image } from "@chakra-ui/react";
import AppContext from "../../context/AppContext";
import { useInterval } from "react-use";
import AudioStream from "./AudioStream";

function MJPEGStream() {
    const videoRef = useRef(null);
    const { streamActive, setStreamActive } = useContext(AppContext);

    const imageUrl = `http://xyz.dashd.io:8080`;

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const response = await fetch(imageUrl, { method: "HEAD" });
                console.log("MJPEG stream metadata:", response.headers);
            } catch (error) {
                console.error("Error fetching MJPEG stream metadata:", error);
            }
        };

        if (streamActive) {
            fetchMetadata();
        }
    }, [streamActive, imageUrl]);

    return (
        <>
            <AudioStream />
            <Box
                position="absolute"
                top="0px"
                bottom="0px"
                left="0px"
                right="0px"
                visibility={streamActive ? "visible" : "hidden"}
                zIndex="1"
                flex="1"
                display="flex"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                backgroundColor="gray.800"
                pointerEvents="none"
                userSelect="none"
            >
                <Image
                    src={imageUrl}
                    width="100%"
                    height="100%"
                    objectFit="contain"
                    onError={() => {
                        console.log("MJPEG stream error!");
                        setStreamActive(false);
                    }}
                    onLoad={() => {
                        console.log("MJPEG stream loaded!");
                        setStreamActive(true);
                    }}
                ></Image>
            </Box>
        </>
    );
}

export default MJPEGStream;

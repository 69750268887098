import React, { useRef, useEffect } from "react";

const AudioStream = () => {
    const audioContextRef = useRef(null);
    const sampleRateRef = useRef(48000);
    const channelsRef = useRef(2);
    const bufferDurationRef = useRef(1); // seconds, increased from 0.5
    const sourceBufferRef = useRef([]);
    const websocketRef = useRef(null);
    const isBufferFilledRef = useRef(false);
    const nextPlaybackTimeRef = useRef(0); // The next scheduled playback time

    useEffect(() => {
        const startAudioStream = () => {
            audioContextRef.current = new AudioContext({
                sampleRate: sampleRateRef.current,
            });
            startWebSocket("ws://xyz.dashd.io:8081/audio-stream");
        };

        const startWebSocket = (websocketUrl) => {
            websocketRef.current = new WebSocket(websocketUrl);
            websocketRef.current.binaryType = "arraybuffer";

            websocketRef.current.onmessage = (event) => {
                let incomingData = new Float32Array(event.data);
                let samplesPerChannel =
                    incomingData.length / channelsRef.current;
                let deinterleavedData = [];
                for (let c = 0; c < channelsRef.current; c++) {
                    let channelData = incomingData.subarray(
                        c * samplesPerChannel,
                        (c + 1) * samplesPerChannel
                    );
                    deinterleavedData.push(channelData);
                }
                sourceBufferRef.current.push(deinterleavedData);

                if (
                    !isBufferFilledRef.current &&
                    sourceBufferRef.current.length * samplesPerChannel >=
                        bufferDurationRef.current * sampleRateRef.current
                ) {
                    isBufferFilledRef.current = true;
                    nextPlaybackTimeRef.current =
                        audioContextRef.current.currentTime;
                    playAudio();
                } else if (isBufferFilledRef.current) {
                    playAudio();
                }
            };

            websocketRef.current.onclose = () => {
                if (audioContextRef.current) {
                    audioContextRef.current.close();
                }
            };
        };

        const stopWebSocket = () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };

        const playAudio = () => {
            if (sourceBufferRef.current.length === 0) {
                return;
            }

            let deinterleavedData = sourceBufferRef.current.shift();
            let samplesPerChannel = deinterleavedData[0].length;

            const audioBuffer = audioContextRef.current.createBuffer(
                channelsRef.current,
                samplesPerChannel,
                sampleRateRef.current
            );
            for (let c = 0; c < channelsRef.current; c++) {
                audioBuffer.copyToChannel(deinterleavedData[c], c);
            }

            const bufferSource = audioContextRef.current.createBufferSource();
            bufferSource.buffer = audioBuffer;
            bufferSource.connect(audioContextRef.current.destination);

            const bufferDuration = audioBuffer.duration;
            const currentTime = audioContextRef.current.currentTime;
            const scheduleTime = Math.max(
                currentTime,
                nextPlaybackTimeRef.current
            );
            bufferSource.start(scheduleTime);
            nextPlaybackTimeRef.current = scheduleTime + bufferDuration;

            bufferSource.onended = () => {
                playAudio();
            };
        };

        setTimeout(startAudioStream, 3000);

        return () => {
            stopWebSocket();
        };
    }, []);

    return null;
};

export default AudioStream;

import { Icon } from "@blueprintjs/core";
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Checkbox,
    Text,
    HStack,
} from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocalStorage } from "react-use";

function SafetyWarningModal({ children }) {
    const [isSafetyConfirmed, setSafetyConfirmed] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [open, setOpen] = useState(false);

    // useLayoutEffect(() => {
    //     // DEBUG
    //     setOpen(true);
    //     setSafetyConfirmed(false);
    // }, []);

    useEffect(() => {
        if (!isSafetyConfirmed) {
            setOpen(true);
        }
    }, [isSafetyConfirmed]);

    const onClose = () => {
        setSafetyConfirmed(true);
        setOpen(false);
    };

    if (isSafetyConfirmed) {
        return children;
    }

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isCentered
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent minH="300px">
                <ModalHeader fontSize="2xl">Safety Disclaimer</ModalHeader>
                <ModalBody>
                    <Text fontSize="lg" fontWeight="bold" mb="20px">
                        <Text
                            display="inline-block"
                            color="orange.300"
                            mr="5px"
                        >
                            <Icon icon="warning-sign" size="24"></Icon>
                        </Text>
                        DashDisplay is only for use while parked.
                    </Text>
                    <Text fontSize="lg">
                        By continuing, you agree that you will observe safe
                        behavior and not use the system while driving.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <HStack flex="1">
                        <Checkbox
                            size="lg"
                            isChecked={dontShowAgain}
                            onChange={() => setDontShowAgain(!dontShowAgain)}
                        >
                            I agree
                        </Checkbox>
                        <Box flex="1"></Box>
                        <Button
                            color="white"
                            bg="rgba(0,0,0,0.9)"
                            _hover={{
                                color: "white",
                                bg: "black",
                            }}
                            _active={{
                                color: "white",
                                bg: "black",
                            }}
                            isDisabled={!dontShowAgain}
                            mr={3}
                            size="lg"
                            onClick={onClose}
                        >
                            Agree & Continue&nbsp;
                            <Icon icon="arrow-right" />
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default SafetyWarningModal;

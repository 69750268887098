import { Box, Button, useConst, Text, Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,

    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
Input} from "@chakra-ui/react";
import { Icon } from '@blueprintjs/core';
import { useState } from "react";

function SettingsModal({ isOpen, onConfirm, onCancel }) {
    const [peerIp, setPeerIp] = useState(window.localStorage.getItem('signalling_server_ip') || 'localhost');

    const save = () => {
        // persist IP to settings
        window.localStorage.setItem('signalling_server_ip', peerIp);

        // Refresh page
        window.location.reload();

        onConfirm();
    }

    return (

        <Modal size="xl" isOpen={isOpen} onClose={() => onCancel()}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <FormControl>
        <FormLabel>Server IP</FormLabel>
        <Input type='text' value={peerIp} onChange={(e)=>{
            setPeerIp(e.target.value);
        }} />
        <FormHelperText>IP address of your laptop.</FormHelperText>
        </FormControl>
        </ModalBody>

        <ModalFooter>
            <Button mr="2" variant="outline" onClick={() => onCancel()}>Cancel</Button>
            <Button colorScheme='blue' rightIcon={(<Icon icon="arrow-right" />)} onClick={(e) => {
                e.preventDefault();
                save();
            }}>Save</Button>
        </ModalFooter>
        </ModalContent>
    </Modal>
    )
}

export default SettingsModal;
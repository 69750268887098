import ReactDOM from 'react-dom';
import './assets/css/index.scss';
import RootScreen from './components/root-screen/RootScreen';
import ChakraContextProvider from './context/ChakraProviderContext';
import { AppContextProvider } from './context/AppContext';
//import './services/webrtc.service';

ReactDOM.render(
  <ChakraContextProvider>
    <AppContextProvider>
      <RootScreen />
    </AppContextProvider>
  </ChakraContextProvider>
, document.getElementById('root'));
/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from "react";
// import webrtcService from "../services/webrtc.service";
import queryString from "query-string";
import { useLocalStorage } from "react-use";

const AppContext = React.createContext({});

function AppContextProvider({ children }) {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [streamActive, setStreamActive] = useState(false);
    const [audioActive, setAudioActive] = useState(false);

    useEffect(() => {
        let params = queryString.parse(location.search);
        if (params && params.fullscreenReferrer) {
            setIsFullscreen(true);
        }

        // webrtcService.onStateChange = (active) => {
        //     setStreamActive(active);
        // }
        // webrtcService.start();
    }, []);

    return (
        <AppContext.Provider
            value={{
                streamActive,
                setStreamActive,
                isFullscreen,
                audioActive,
                setAudioActive,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export { AppContextProvider };
export default AppContext;
